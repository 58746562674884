<template>
  <BisnisModelPlnbbForm mode="Tambah" module="Bisnis Model PLNBB"> </BisnisModelPlnbbForm>
</template>

<script>
import BisnisModelPlnbbForm from './form';

const BisnisModelPlnbbAdd = {
  name: 'BisnisModelPlnbbAdd',
  components: { BisnisModelPlnbbForm },
};

export default BisnisModelPlnbbAdd;
</script>
